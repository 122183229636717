export enum RelaxEnum {
  active = 1,
  Inactive = 0,
}

export enum ScoreStatusEnum {
  Pending = "pending",
  Executing = "executing",
  Finished = "finished",
  Failed = "failed",
}

export enum SequenceTypeEnum {
  Raw = "raw",
  New = "new",
}

export enum SchemeEnum {
  Kabat = "kabat",
  Imgt = "imgt",
  Chothia = "chothia",
  Aho = "aho",
}

export enum CdrDefEnum {
  Kabat = "kabat",
  Imgt = "imgt",
  Chothia = "chothia",
  North = "north",
}

export enum RecommendModelEnum {
  GeoHumAb = "GeoHumAb",
  ESM23B = "ESM2-3B",
  ESM1V650M = "ESM1V-650M",
  SaProt = "SaProt",
  // GermlineSequences = "Germline Sequences",
}

export enum AdvisorEnum {
  AiCopilot = "ai_copilot",
  GermlineSequence = "germline_sequence",
}

export enum ScoreModelEnum {
  GeoHumanness = "GeoHumanness",
  SideChainPKa = "SideChainPKa",
  Charge = "Charge",
  ChargedPatch = "ChargedPatch",
  Hydrophobicity = "Hydrophobicity",
  HydrophobicPatch = "HydrophobicPatch",
  PTM = "PTM",
  Sequence = "Sequence",
}

export enum MutationModelEnum {
  Geohumab = "geohumab",
  CdrGrafting = "cdr_grafting",
  OneClickHumanization = "one_click_humanization",
}

export enum TreeItemTypeEnum {
  File = "file",
  Job = "job",
  Dataset = "dataset",
}

export enum AlignCmdEnum {
  Align = "align",
  Super = "super",
  Cealign = "cealign",
  Dockq = "dockq",
}

export enum AlignStatusEnum {
  NotCreated = "not_created",
  Executing = "executing",
  Finished = "finished",
  Failed = "failed",
}

export enum TaskPollingEnum {
  Pending = "pending",
  Executing = "executing",
  Finished = "finished",
  Failed = "failed",
  Deleted = "deleted",
}

export enum TaskTypeEnum {
  PtmRawSeq = "ptm_raw_seq",
  PtmNewSeq = "ptm_new_seq",
  SurfaceRawSeq = "surface_raw_seq",
  SurfaceNewSeq = "surface_new_seq",
  SequenceNewMutate = "sequence_new_mutate",
}

export enum SeqAlignTypeEnum {
  ByPos = "by_pos",
  AlignToNewSequence = "align_to_new_sequence",
}

export enum MutationTypeEnum {
  SeqAll = "seq_all",
  SeqH = "seq_H",
  SeqL = "seq_L",
}

export enum EnzymeObjectiveEnum {
  EnzymeActivity = "EnzymeActivity",
  AcidOrAlkaliTolerance = "AcidOrAlkaliTolerance",
  ThermoTolerance = "ThermoTolerance",
  Stereoselectivity = "Stereoselectivity",
  Solubility = "Solubility",
}

export enum ComputeEnum {
  Auto = 1,
  NotAuto = 0,
}

export enum WidgetTypeEnum {
  JobCreate = "job_create",
  TextViewer = "text_viewer",
  ImageViewer = "image_viewer",
  StructureViewer = "structure_viewer",
  JobResult = "job_result",
  JobDetail = "job_detail",
  CsvViewer = "csv_viewer",
}

export enum ProteinObjectiveEnum {
  Fitness = "Fitness",
  Thermostability = "Thermostability",
  Solubility = "Solubility",
}

export enum CharacterTypeEnum {
  StructAlgin = "struct_algin",
  Fitness = "fitness",
}

export enum MsaModeEnum {
  Mmseqs2UnirefEnv = "mmseqs2_uniref_env",
  Mmseqs2Uniref = "mmseqs2_uniref",
  SingleSequence = "single_sequence",
}

export enum PairModeEnum {
  Unpaired = "unpaired",
  Paired = "paired",
  UnpairedPaired = "unpaired_paired",
}
