import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { JobTypeEnum } from "@services/job/enum/job.enum";
import {
  CifIdToObjectModel,
  FileSelectModel,
  FileIdRefArrModel,
  TreeActionModel,
} from "@services/editor/model/editor.model";
import { JobRerunParameterModel } from "@services/editor/model/job-panel.model";
import { WidgetTypeEnum } from "@services/editor/enum/editor.enum";
import { WidgetModel } from "@services/editor/model/widget.model";

export interface EditorState {
  value: number;
  incrementAmount: number;
  entities: any[];
  loading: boolean;

  jobPanelVisible: boolean;
  selectJobType: JobTypeEnum | null;

  // higlight Tree
  tempCifFileSet: Record<string, boolean>;
  cifFileSet: Record<string, boolean>;

  fileIdMapStructureRef: Record<string, string>;

  fileIdMapChildrenRefArr: Record<string, string[]>;

  structureFiles: FileSelectModel[];

  treeAction: TreeActionModel | null;

  structureRefs: string[];

  widgetList: WidgetModel[];

  selectedWidget: string;

  jobBarVisible: boolean;

  fileBarVisible: boolean;

  hasUnread: boolean;

  popupVisiable: boolean;

  rerunParameters?: JobRerunParameterModel;
}

const initialState: EditorState = {
  value: 0,
  incrementAmount: 1,
  entities: [],
  loading: false,

  // Automated creation of parameters

  jobPanelVisible: false,
  selectJobType: null,

  tempCifFileSet: {},
  cifFileSet: {},

  fileIdMapStructureRef: {},
  fileIdMapChildrenRefArr: {},
  structureFiles: [],
  treeAction: null,
  structureRefs: [],

  widgetList: [
    {
      widgetType: WidgetTypeEnum.StructureViewer,
      title: "Structure viewer",
      key: WidgetTypeEnum.StructureViewer,
      closable: false,
    },
  ],

  selectedWidget: WidgetTypeEnum.StructureViewer,

  jobBarVisible: false,
  fileBarVisible: true,
  hasUnread: false,

  popupVisiable: false,
};

// export const getFileTreeByProject = createAsyncThunk(
//   "editor/fetchFileTree",
//   async (pid: string, thunkAPI) => {
//     const state = thunkAPI.getState() as RootState;

//     const data = await getFileJobListApi(pid);

//     return aggregateData(data, state.editor.fileTreeList);
//   }
// );

export const editorSlice = createSlice({
  name: "editor",
  initialState,

  reducers: {
    increment: (state) => {
      state.value += state.incrementAmount;
    },
    decrement: (state) => {
      state.value -= state.incrementAmount;
    },
    changeIncrementAmount: (state, action: PayloadAction<number>) => {
      state.incrementAmount = action.payload;
    },

    addFileIdMapStructureRef(state, action: PayloadAction<CifIdToObjectModel>) {
      state.fileIdMapStructureRef = {
        ...state.fileIdMapStructureRef,
        [action.payload.cifId]: action.payload.ref,
      };
    },
    removeFileIdMapStructureRef(state, action: PayloadAction<string>) {
      delete state.fileIdMapStructureRef[action.payload];
    },
    clearFileIdMapStructureRef(state) {
      state.fileIdMapStructureRef = {};
    },

    addFileIdMapChildrenRefArr(
      state,
      action: PayloadAction<FileIdRefArrModel>
    ) {
      state.fileIdMapChildrenRefArr = {
        ...state.fileIdMapChildrenRefArr,
        [action.payload.cifId]: action.payload.arr,
      };
    },
    removeFileIdMapChildrenRefArr(state, action: PayloadAction<string>) {
      delete state.fileIdMapChildrenRefArr[action.payload];
    },
    clearFileIdMapChildrenRefArr(state) {
      state.fileIdMapChildrenRefArr = {};
    },

    setJobPanelVisible(state, action: PayloadAction<boolean>) {
      state.jobPanelVisible = action.payload;
    },
    setSelectJobType(state, action: PayloadAction<JobTypeEnum | null>) {
      state.selectJobType = action.payload;
    },
    addTempCifFile(state, action: PayloadAction<string>) {
      state.tempCifFileSet[action.payload] = true;
    },
    addCifFile(state, action: PayloadAction<string>) {
      state.cifFileSet = {
        ...state.tempCifFileSet,
        [action.payload]: true,
      };
    },
    switchCifFile(state) {
      state.cifFileSet = {
        ...state.tempCifFileSet,
      };
    },
    removeCifFile(state, action: PayloadAction<string>) {
      delete state.cifFileSet[action.payload];
      delete state.tempCifFileSet[action.payload];
    },

    highlightFile(state, action: PayloadAction<string>) {
      state.cifFileSet = {
        [action.payload]: true,
      };
    },

    resetFile(state) {
      state.cifFileSet = {};
    },

    resetFileSelect(state) {
      state.cifFileSet = {};
      state.tempCifFileSet = {};
    },

    addStructureFiles: (state, action: PayloadAction<FileSelectModel>) => {
      state.structureFiles = [...state.structureFiles, action.payload];
    },
    resetStructureFiles: (state) => {
      state.structureFiles = [];
    },
    removeStructureFile: (state, action: PayloadAction<string>) => {
      const structureFiles = state.structureFiles;
      const list = structureFiles.filter((item) => {
        return item.id !== action.payload;
      });
      state.structureFiles = list;
    },

    dispatchTreeMode: (
      state,
      action: PayloadAction<TreeActionModel | null>
    ) => {
      state.treeAction = action.payload;
    },

    setStructureRefs: (state, action: PayloadAction<string[]>) => {
      state.structureRefs = action.payload;
    },

    addWidget: (state, action: PayloadAction<WidgetModel>) => {
      const { key } = action.payload;
      const list = state.widgetList;
      const target = list.find((item) => {
        return item.key === key;
      });

      if (target) {
        state.selectedWidget = key;
      } else {
        state.selectedWidget = key;
        state.widgetList = [...state.widgetList, action.payload];
      }
    },

    removeWidget: (state, action: PayloadAction<string>) => {
      state.widgetList = state.widgetList.filter((item) => {
        return item.key !== action.payload;
      });
    },

    resetWidget: (state) => {
      state.widgetList = [
        {
          widgetType: WidgetTypeEnum.StructureViewer,
          title: "Structure viewer",
          key: WidgetTypeEnum.StructureViewer,
          closable: false,
        },
      ];

      state.selectedWidget = WidgetTypeEnum.StructureViewer;
    },

    setSelectedWidget: (state, action: PayloadAction<string>) => {
      state.selectedWidget = action.payload;
    },

    toggleJobBarVisible: (state, action: PayloadAction<boolean>) => {
      const visible = action.payload;

      if (visible) {
        state.fileBarVisible = false;
      }
      state.jobBarVisible = visible;
    },

    toggleFileBarVisible: (state, action: PayloadAction<boolean>) => {
      const visible = action.payload;

      if (visible) {
        state.jobBarVisible = false;
      }

      state.fileBarVisible = visible;
    },
    setHasUnread: (state, action: PayloadAction<boolean>) => {
      state.hasUnread = action.payload;
    },

    setPopupVisiable: (state, action: PayloadAction<boolean>) => {
      state.popupVisiable = action.payload;
    },

    setRerunParameters: (
      state,
      action: PayloadAction<JobRerunParameterModel | undefined>
    ) => {
      state.rerunParameters = action.payload;
    },
  },

  extraReducers: (builder) => {
    // builder.addCase(getFileTreeByProject.pending, (state) => {
    //   state.fileTreeLoading = true;
    // });
    // builder.addCase(getFileTreeByProject.fulfilled, (state, { payload }) => {
    //   state.fileTreeLoading = false;
    //   state.fileTreeList = payload.treeList;
    //   state.jobFileCheckpoint = payload.checkpoint;
    //   if (!state.jobFileInitLoaded) {
    //     state.jobFileInitLoaded = true;
    //   }
    // });
    // builder.addCase(getFileTreeByProject.rejected, (state) => {
    //   state.fileTreeLoading = false;
    // });
  },
});

export const {
  increment,
  decrement,
  changeIncrementAmount,
  setJobPanelVisible,
  setSelectJobType,
  addTempCifFile,
  addCifFile,
  removeCifFile,
  resetFileSelect,
  addFileIdMapStructureRef,
  removeFileIdMapStructureRef,
  clearFileIdMapStructureRef,
  addStructureFiles,
  resetStructureFiles,
  removeStructureFile,
  addFileIdMapChildrenRefArr,
  removeFileIdMapChildrenRefArr,
  clearFileIdMapChildrenRefArr,
  dispatchTreeMode,
  setStructureRefs,
  addWidget,
  removeWidget,
  setSelectedWidget,
  toggleJobBarVisible,
  toggleFileBarVisible,
  highlightFile,
  switchCifFile,
  resetFile,
  resetWidget,
  setHasUnread,
  setPopupVisiable,
  setRerunParameters,
} = editorSlice.actions;

export default editorSlice.reducer;
